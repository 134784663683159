<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="pa-4">
          <v-card-title class="headline"
            >Reparatie informatie indienen</v-card-title
          >
          <v-card-text>
            <v-text-field
              label="Titel"
              :value="repairInfo.title"
              readonly
            ></v-text-field>
            <v-text-field label="Adres" :value="adres" readonly></v-text-field>
            <v-textarea
              label="Opdracht omschrijving"
              v-model="repairInfo.description"
            ></v-textarea>

            <v-text-field
              label="Status"
              :value="repairInfo.status"
              readonly
            ></v-text-field>
            <v-text-field
              label="Uitvoerdatum"
              :value="repairInfo.executionDate"
              readonly
            ></v-text-field>

            <v-textarea
              label="Wat zijn de uitgevoerde werkzaamheden"
              v-model="notes"
              hint="Voeg extra details toe"
              persistent-hint
            ></v-textarea>

            Afbeeldingen toevegen
            <div
              id="my-strictly-unique-vue-upload-multiple-image"
              style="display: flex; justify-content: center;"
            >
              <vue-upload-multiple-image
                @upload-success="uploadImageSuccess"
                @before-remove="beforeRemove"
                @edit-image="editImage"
                :data-images="images"
                idUpload="myIdUpload"
                editUpload="myIdEdit"
                dragText="Sleep afbeeldingen"
                browseText="of klik om te selecteren"
                dropText="Zet uw bestanden hier neer"
                markIsPrimaryText="Instellen als standaard"
                primaryText="standaard"
                popupText="Dit is de standaard afbeelding"
              ></vue-upload-multiple-image>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="updateRepairByMechanic()">Indienen</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" v-if="!validLink">
      <v-col cols="12" md="8">
        <v-alert type="error" class="mt-5">
          <h3>De link is ongeldig</h3>
          <p>Neem contact op met jouw contactpersoon</p>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiRepair from "../api/apiRepair";
import VueUploadMultipleImage from "vue-upload-multiple-image";

export default {
  components: {
    VueUploadMultipleImage,
  },
  data() {
    return {
      notes:"",
      repairId:null,
      adres: "",
      repairInfo: {},
      extraInfo: "",
      validLink: false,
      formDataImages: [],
      images: [],
    };
  },
  created() {
    this.checkLinkValidity(this.$route.params);
    console.log("created aangeroepen");
    console.log("URL" + JSON.stringify(this.$route.params));
  },
  methods: {
    checkLinkValidity() {
      const code = this.$route.params.code;
      const id = this.$route.params.id;
      const fullLink = `/ReparatieBewijs/submission/${code}/${id}`;
      console.log("Volledige link:", fullLink);
      const data = {
        link: fullLink,
      };
      apiRepair
        .validateLink(data)
        .then((response) => {
          const linkIsValid = response.isValid;
          console.log("Link is valid:", linkIsValid);
          this.validLink = linkIsValid;
          this.repairInfo = response.repairInfo;
          this.repairId = response.repairInfo.repairId;
          console.log("Link is repairId:", this.repairId);
          if (response.repairInfo && response.repairInfo.propertyDetails) {
            this.adres = response.repairInfo.propertyDetails.location;
           
          }
        })
        .catch((error) => {
          console.error(error);
          this.validLink = false;
        });
    },

    async updateRepairByMechanic() {
     
      try {
       const data = {
        notes: this.notes,
        updatedBy: "Toegewezen monteur"
       }
       const id = this.repairId;
        const response = await apiRepair.updateRepairByMechanic(id, data);

         this.note = "";
        console.log(response);
        this.$swal.fire("Yes!", "Het bijwerken is gelukt!", "success");
      } catch (error) {
        console.error(error);
        this.$swal.fire(
          "Er is een foutmelding!",
          "Probeer het nogmaals!",
          "error"
        );
      }
    },

    //Afbeeldingen toevoegen:

    uploadImageSuccess(formData, index, fileList) {
      console.log("data", formData, index, fileList);
      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
      } else {
        //
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
    },
  },
};
</script>

<style scoped>
.center-text {
  text-align: center;
}
</style>
