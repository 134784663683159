import {API} from "./api.base.js"
export default {
  async createRepair(data) {
    try {
      const response = await API.post('/api/rest/v2/repairs', data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getRepairs() {
    try {
      const response = await API.get('/api/rest/v2/repairs');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getRecentRepairs() {
    try {
      const response = await API.get('/api/rest/v2/repairs/last/repairs');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getRepaircalander() {
    try {
      const response = await API.get(`/api/rest/v2/repairs/calander`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getRepairbyId(id) {
    try {
      const response = await API.get(`/api/rest/v2/repairs/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async deleteRepair(id) {
    try {
      const response = await API.delete(`/api/rest/v2/repairs/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async updateRepairbyId(id, repair) {
    try {
      const response = await API.put(`/api/rest/v2/repairs/${id}`,repair);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async generateSubmissionLink(id) {
    try {
      const response = await API.post(`/api/rest/v2/repairs/${id}/submission-link`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  //Data
  
  async getNotActiveStatus() {
    try {
      const response = await API.get('/api/rest/v2/repairs/notactive/repairs');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getActiveStatus() {
    try {
      const response = await API.get('/api/rest/v2/repairs/active/repairs');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },

  async getTotalRepairs() {
    try {
      const response = await API.get('/api/rest/v2/repairs/total/repairs');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getReport() {
    try {
      const response = await API.get('/api/rest/v2/repairs/report/repairs');
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  // async GenerateRepairLink(id) {
  //   try {
  //     const response = await API.post(`/api/rest/v2/repairs/${id}/submission-link`);
  //     return response.data;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },
  async getRepairLink(id) {
    try {
      const response = await API.get(`/api/rest/v2/repairs/submission-link/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  },
  async getRepairStatus() {
    try {
      const response = await API.get(`/api/rest/v2/repairs/status/repairs`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
    
  },
  async validateLink(data) {
    try {
        const response = await API.post(`/api/rest/v2/repairs/submission-link/validation`, data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
},


//Update form

async updateRepairByMechanic(id,data) {
  try {
      const response = await API.post(`/api/rest/v2/mechanic/form/update/${id}`, data);
      return response.data;
  } catch (error) {
      console.error(error);
  }
}

};
